import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import { T } from '@deity-io/falcon-i18n';
import StructuralDataBreadcrumbs from '../../../components/StructuralData/StructuralDataBreadcrumbs';
import './Breadcrumbs.scss';

// Transform a link to a path that we can use
const extractPath = (url) => {
    // Assume that it's always safe to redirect to /
    const defaultRedirect = "/";

    if(URL && url) {
        const parsedUrl = new URL(url);

        if(!parsedUrl) {
            return defaultRedirect;
        }

        return parsedUrl.pathname;
    }
    return defaultRedirect;
}

const Breadcrumbs = ({routeData, isCategory, name}) => {
    // Handle search
    if(!routeData && name){
        return (
            <div className="breadcrumbs">
                <Link className="breadcrumbs__link" to="/">
                    <T id="home" />
                </Link>
                <span><T id="searchResult.results" name={name}/></span>
            </div>
        )
    }

    // Handle category & product
    const {breadcrumbs} = routeData;
    return <div className="breadcrumbs">
                <StructuralDataBreadcrumbs 
                    breadcrumbs= {breadcrumbs}
                    isCategory = {isCategory}
                    routeData = {routeData}
                    name = {name}/>
                    
        {isCategory && <Link className="breadcrumbs__link" to="/">
            <T id="home" />
        </Link>}
        {breadcrumbs && breadcrumbs.map(({category_name, category_level, category_url}) => {
            return <Link
                to={`${extractPath(category_url)}`}
                className="breadcrumbs__link"
                key={category_level}
            >{category_name}</Link>
        })
        }
        {!isCategory && <Link className="breadcrumbs__link" to={`/${routeData.canonical_url}`}>
            {routeData.name}
        </Link>}
        <span>{isCategory && routeData ? routeData.name : name}</span>
    </div>
}


Breadcrumbs.propTypes = {
    routeData: PropTypes.object,
    name: PropTypes.string,
};

export default React.memo(Breadcrumbs);