import React from "react";
import loadable from '@loadable/component';
import {useLocation} from 'react-router-dom';
import {BuildAbsoluteUrl} from "../../utils/urlBuilder";
const Helmet = loadable(() => import('react-helmet'));
function prepareName(name){
    return name.replace(/\"/g, '\'')
}
export default function StructuralDataBreadcrumbs({ breadcrumbs, isCategory, routeData, name }) {
    const location = useLocation();
    return <Helmet>
        <script type="application/ld+json">{`
        {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement":
            [
                ${breadcrumbs.length > 0 ? breadcrumbs.map(({category_name, category_level, category_url}) => {
                    return `{
                        "@type": "ListItem",
                        "position": "${category_level - 1}",
                        "item":
                        {
                            "@id": "${category_url}",
                            "name": "${prepareName(category_name)}"
                        }
                    }`
                }) : ""}
                ${breadcrumbs.length > 0? "," : ""}
                ${!isCategory ?`{ 
                    "@type": "ListItem",
                    "position": "${breadcrumbs.length + 1}",
                    "item":
                    {
                        "@id": "${BuildAbsoluteUrl(routeData.canonical_url)}",
                        "name": "${prepareName(routeData.name)}"
                    }},` : ""}
                    {
                        "@type": "ListItem",
                        "position": "${isCategory? breadcrumbs.length + 1 : breadcrumbs.length + 2}",
                        "item":
                        {
                            "@id" : "${BuildAbsoluteUrl(location.pathname)}",
                            "name": "${isCategory? prepareName(routeData.name) : prepareName(name)}"
                        }
                    }
                ]
            }
        `}</script>
    </Helmet>
}